@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.faq {
  @include page-sizing;
  transition: height 10s;

  &__card {
    @include expandable-card;
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 2.75%;
    }

    p:first-of-type {
      padding-top: 1em;
    }
  }

  &__question {
  }
  &__text {
    padding-bottom: 1em;
  }

  &__link {
    text-decoration: none;
    color: $eacc-green;
  }

  &__subheader {
    margin-top: 1em;
  }
}
