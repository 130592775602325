@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;

.guide-form {
  @include page-sizing;

  align-items: center;
  &__divider {
    margin: auto;
    background-color: $eacc-green;
    width: 100px;
    height: 3px;
    margin-bottom: 40px;
  }

  &__text {
    max-width: 900px;
    margin: auto;
    font-size: 1rem;
    margin-bottom: 2%;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 47.25%;
      margin-right: 5.5%;
    }
  }

  &__title {
    font-size: 26px;
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 2em;
  }

  &__input {
    outline: none;
    background-color: #eee;
    border: none;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: $font-stack;

    &::placeholder {
      color: #999;
    }

    &--email {
      @include tablet {
        margin-left: 1rem;
      }
    }

    &--province {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1em;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    @include green-button;
    @include button-animation;
    width: 146px;
    padding: 6px 20px;
    text-decoration: none;
    margin-left: auto;
    margin-bottom: 1rem;

    &:after {
      content: ">";
      color: white;
      position: absolute;
      opacity: 0;
      top: 50%;
      translate: 0 -50%;
      right: -10px;
      transition: 0.5s;
    }

    &:hover {
      padding-right: 40px;
    }

    &:hover:after {
      opacity: 1;
      right: 4%;
    }
  }
}
