@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.news {
  display: flex;
  width: 80%;
  max-width: 1080px;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  padding: 5rem 0;

  @include tablet {
    flex-direction: row;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 20px;
    min-height: 500px;
    border: 2px solid $eacc-grey;
    box-sizing: border-box;

    @include tablet {
      width: 47.25%;
    }

    &--left {
      border: 2px solid $eacc-green;
      margin-bottom: 2rem;

      @include tablet {
        margin-bottom: 0;
        margin-right: 5.5%;
      }
    }
  }

  &__image {
    width: 8rem;
    height: 8rem;
    margin-bottom: 30px;
  }

  &__title {
    color: $eacc-green;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
  }

  &__text-wrapper {
    margin-top: 2rem;
    align-self: flex-start;
    text-align: left;
  }

  &__event-title {
    font-size: 18px;
    font-weight: 700;
  }
  &__event-text {
    font-size: 18px;
    font-weight: 500;
  }

  &__text {
    color: #666;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7em;

    &--padding {
      padding: 1em 0;
    }
  }

  &__event-link {
    display: inline-block;
    margin-top: 10px;
    color: $eacc-green;
    text-decoration: none;
    font-size: 100%;
    font-weight: 500;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
  }
}
