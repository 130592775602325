@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
.join {
  @include page-sizing;

  &__text {
    padding-bottom: 1em;
    line-height: 1.7em;
    font-size: 14px;
    font-weight: 500;
    color: #666;

    &--send {
      padding-top: 2rem;
    }

    &--email {
      margin-top: 1em;
      margin-bottom: 2.75%;
    }

    &--link {
      color: $eacc-green;
      text-decoration: none;
    }
  }

  &__button {
    @include green-button;
    text-decoration: none;
    min-height: 50px;
    align-self: flex-start;
    font-size: 1.2rem;
    padding: 0.3em 1em;
    letter-spacing: 0;
    margin: 2.75% 0;

    transition: 0.5s;
    &:after {
      content: ">";
      color: white;
      position: absolute;
      opacity: 0;
      top: 50%;
      translate: 0 -50%;
      right: -10px;
      transition: 0.5s;
    }

    &:hover {
      padding-right: 40px;
    }

    &:hover:after {
      opacity: 1;
      right: 4%;
    }

    &--ethics {
      margin-bottom: 0;

      &:hover:after {
        opacity: 1;
        right: 5%;
      }
    }
  }

  &__address {
    font-weight: 700;
    font-size: 14px;
  }
}
