@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
  font-size: 900;
}

$font-stack: "Montserrat", sans-serif;
