@use "variables" as *;
@use "colors" as *;
@use "typography" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin nav-list-item {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: #666;
  text-decoration: none;
  display: flex;
  align-items: center;

  @include tablet {
    padding-left: 22px;
  }

  &:hover {
    color: #949494;
  }

  &--active {
    color: $eacc-green;
  }
}

@mixin secondary-nav-list-item {
  @include nav-list-item;
  color: white;
  font-size: 12px;

  &:hover {
    color: #c5deca;
  }
}

@mixin green-button {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  border: 2px solid #33924a;
  letter-spacing: 2px;
  line-height: 1.7em;
  font-size: 1.45rem;
  font-weight: 500;
  background-color: #33924a;
  padding: 0.3em 1em;
  text-decoration: none;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin page-sizing {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 117px auto;
  max-width: 1080px;
}

@mixin expandable-card {
  @include card-animation;
  cursor: pointer;
  border: solid 1px rgb(226, 226, 226);
  padding: 1rem;
  background-color: rgb(247, 247, 247);
  transition: all 1s;
  box-sizing: border-box;

  &--color {
    background-color: white;
  }
}

@mixin card-animation {
  &--expanded {
    background-color: white;
    animation: expand 2s;
    height: auto;
  }

  &--close {
    animation: close 1s;
  }
}

@mixin link-styling {
  text-decoration: none;
  color: $eacc-green;
}

@mixin button-animation {
  transition: 0.5s;
  &:after {
    content: ">";
    color: white;
    position: absolute;
    opacity: 0;
    top: 50%;
    translate: 0 -50%;
    right: -10px;
    transition: 0.5s;
  }

  &:hover {
    padding-right: 40px;
  }

  &:hover:after {
    opacity: 1;
    right: 8%;
  }
}
@keyframes expand {
  from {
    max-height: 38px;
    overflow-y: hidden;
    background-color: rgb(247, 247, 247);
  }
  to {
    max-height: 1000px;
    background-color: white;
  }
}

@keyframes close {
  from {
    max-height: 500px;
  }
  to {
    max-height: 1px;
    overflow-y: hidden;
    background-color: rgb(247, 247, 247);
  }
}
