@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.radon-101 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2%;
  &__header {
    color: black;
    padding: 27px 0;
  }

  &__subheader {
    color: black;
    padding: 27px 0;
  }
}
