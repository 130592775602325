@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.slider {
  padding: 80px 0;

  &__controls {
    display: flex;
    justify-content: center;
    position: relative;
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    min-height: 40vw;
  }
  &__image {
    cursor: pointer;
    width: 100%;
    cursor: pointer;

    -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  &__image-wrapper {
    min-width: 80vw;
    min-height: 128px;

    @include tablet {
      min-height: 60vh;
    }
  }

  &__left {
    cursor: pointer;

    position: absolute;
    z-index: 2;
    top: 50%;
    translate: 0 -50%;
    left: 22px;
    animation: slide-left 1s 0s;
  }

  &__right {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 50%;
    translate: 0 -50%;
    right: 22px;
    animation: slide-right 1s 0s;
  }
}

@keyframes slide-left {
  from {
    opacity: 0;
    left: -22px;
  }
  to {
    opacity: 1;
    left: 22px;
  }
}
@keyframes slide-right {
  from {
    opacity: 0;
    right: -22px;
  }
  to {
    opacity: 1;
    right: 22px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
