@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.board-dates {
  @include page-sizing;

  &__text {
    color: #666;
    background-color: #fff;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 1em;

    &--padding {
      padding-bottom: 0.8rem;
    }
  }
  &__link {
    @include link-styling;
  }
  &__list-item {
    @extend .board-dates__text;
    padding-bottom: 0;
    list-style-position: inside;
  }
}
