@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.popup {
  display: none;
  @include tablet {
    display: flex;
    width: 100%;
  }

  &__overlay {
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgb(0, 0, 0, 0.7);
  }

  &__active {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    position: relative;

    max-height: 80vh;
  }
  &__active-image {
    max-width: 80vw;
    max-height: 80vh;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__left {
    cursor: pointer;
    position: absolute;
    left: 1.5rem;
    z-index: 3;
    color: rgb(6, 6, 6);
    font-size: 2rem;
    width: 2rem;

    &--animate {
      translate: -5px 0;
    }
  }
  &__right {
    cursor: pointer;
    position: absolute;
    right: 1.2rem;
    z-index: 3;
    width: 2rem;

    &--animate {
      translate: 5px 0;
    }
  }
  &__close-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    width: fit-content;
    max-height: 80vh;
  }
  &__close {
    cursor: pointer;
    align-self: flex-end;
    margin-right: -0.7rem;
    height: 3rem;
    width: 3rem;
  }

  &__info {
    display: flex;
    justify-content: center;
    bottom: -3rem;
    right: 14rem;
    height: 3rem;
    z-index: 5;
    color: white;
    width: 100%;
  }

  &__title {
    @include tablet {
      text-align: left;
      width: 100%;
      padding-left: 2.25rem;
    }
  }

  &__number {
    @include tablet {
      text-align: right;
      width: 20%;
      margin-right: 3rem;
    }
  }
}
