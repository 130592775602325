@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.members {
  padding-bottom: 4rem;
  &__section {
    padding: 2rem;
    @include flex-center;
  }
  &__subheader {
    margin-top: 4rem;
    font-weight: 400;
    font-size: 0.9rem;
    color: $eacc-grey;
  }

  &__list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      flex-direction: row;
      padding: 0 2rem;
      margin: 0;
    }
  }

  &__bin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include tablet {
    }

    &--listview {
      @include tablet {
        margin-left: auto;
        margin-right: 1rem;
      }
    }

    &--mapview {
      @include tablet {
        margin-right: auto;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__item {
    @include flex-center;
    position: relative;
    cursor: pointer;
    background-color: $eacc-green;
    width: 25rem;
    margin-bottom: 1rem;
    height: 3rem;
    border-radius: 2px;

    transition: 0.5s;
    &:after {
      content: ">";
      color: white;
      position: absolute;
      opacity: 0;
      top: 50%;
      translate: 0 -50%;
      right: -10px;
      transition: 0.5s;
    }

    &:hover {
      padding-right: 5%;
    }

    &:hover:after {
      opacity: 1;
      right: 7%;
    }
  }

  &__title {
    color: white;
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
}
