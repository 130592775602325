@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.secondary-nav {
  display: none;
  @include tablet {
    display: flex;
    justify-content: flex-end;
    color: white;
    background-color: $eacc-green;
    padding: 0.15rem 2rem;
    font-size: 12px;
    font-weight: 600;
    z-index: 3;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;

    @include tablet {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  &__list-item {
    @include secondary-nav-list-item;
  }
}
