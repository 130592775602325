.member {
  width: 100%;
  margin: 54px 0 0;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 54px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 18px;
    color: #333;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 700;
  }

  &__text {
    color: #666;
    background-color: #fff;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 14px;
  }
  &__info-container {
    display: flex;
  }
  &__image {
    object-fit: cover;
    height: 150px;
    width: 100px;
    margin-right: 11%;
  }
}
