@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.board-minutes {
  @include page-sizing;
  justify-content: center;
  align-items: center;

  &__text {
    color: #666;
    background-color: #fff;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 50px;
  }

  &__button {
    @include green-button;
    @include button-animation;
    font-size: 1.15rem;
    text-decoration: none;
    width: 320px;
    margin-bottom: 1rem;
  }
}
