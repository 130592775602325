@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.single-gallery {
  @include page-sizing;

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: solid 2px green;
  }

  &__sponsors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px orange;
  }

  &__gallery {
    @include flex-center;
    align-items: flex-start;

    flex-direction: column;
    // border: solid 2px blue;

    @include tablet {
      justify-content: flex-start;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // border: solid 2px red;

    @include tablet {
      max-width: 20.875%;
      max-height: 290px;
      margin-right: 5.5%;
      margin-bottom: 5.5%;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      transition: all 0.6s;
      transform: translateY(-4px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  &__photo {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    padding: 1rem;
    // border: solid 2px pink;

    @include tablet {
      padding: 0;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;
  }

  &__text {
    padding-bottom: 14px;
    line-height: 1.7em;

    &--bold {
      font-weight: 700;
    }
  }

  &__list {
    list-style: inside;
    margin-bottom: 1rem;

    &--secondary {
      padding-left: 1rem;
    }
  }

  &__list-item {
    color: #666;
    font-size: 14px;
  }

  &__link {
    @include link-styling;
  }

  &__sponsor-image {
    margin: auto;
    height: 10rem;

    &--large {
      width: 50%;
      margin: auto;
      text-align: center;
      align-self: center;
    }
  }
}
