@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.documents {
  @include page-sizing;

  &__subheader {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1em;
  }

  &__title {
    font-weight: 700;
  }

  &__date {
    font-size: 0.9rem;
  }

  &__button {
    @include green-button;
    @include button-animation;
    margin-top: 1.5rem;
    @include desktop {
      margin-left: 2rem;
    }
  }

  &__section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 27px 0;
    @include desktop {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__top--bin {
    // border: solid 1px red;
    margin-right: auto;
  }

  &__link {
    text-decoration: none;
  }

  &__bottom--bin {
    display: flex;
    align-items: center;
    // border: solid 1px blue;
    margin-right: auto;
    margin-left: 0;

    @include tablet {
      margin-right: 0rem;
    }
  }

  &__safety {
    @include page-sizing;
    margin-top: 0;
  }

  &__header {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 0.925rem;
    line-height: 1.7em;
    font-size: 14px;
  }

  &__link {
    @include link-styling;
  }

  &__list {
    list-style: inside;
  }

  &__list-item {
    color: #666;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 14px;
  }

  &__info {
    &--padding {
      padding-top: 0.3rem;
    }
  }
}
