@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.guidelines {
  @include page-sizing;

  &__section {
    flex-direction: column;
    text-align: left;
    color: $eacc-grey;
    @include desktop {
      @include flex-center;
    }
  }

  &__header {
    align-self: flex-start;
    font-size: 1.4rem;
    font-weight: 500;
    padding-bottom: 2rem;

    @include tablet {
    }
  }

  &__container {
    @include flex-center;
    flex-direction: column;
    flex-wrap: wrap;

    @include tablet {
      flex-direction: row;
      flex-wrap: nowrap;
      //   padding-right: rem;
      max-width: 80rem;
    }
  }

  &__bin {
    @include tablet {
      margin-right: 5rem;
      width: 70%;
    }
  }

  &__title {
    font-weight: 700;
    line-height: 1.7em;
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  &__body {
    font-weight: 500;
    line-height: 1.7em;
    font-size: 1rem;
    color: #666;
  }

  &__issued {
    margin: 1rem 0 2rem;
    color: #666;
    font-weight: 500;
    font-size: 1rem;
  }

  &__link {
    @include link-styling;
    font-weight: 600;
    color: $eacc-green;
    margin-bottom: 2rem;
  }

  // &__hotlink {
  //   padding-bottom: 2rem;
  // }

  &__button {
    text-decoration: none;
    margin-right: auto;
    @include tablet {
      align-self: flex-start;
      padding-top: 3rem;
    }
  }

  &__download {
    @include green-button;
    @include button-animation;
    text-decoration: none;
  }

  &__subheader {
    margin-bottom: 1.5rem;
  }

  &__list {
    line-height: 1.75rem;
    font-weight: 500;
    margin-bottom: 1rem;
    list-style-position: inside;
  }

  &__item {
    color: #666;
    font-size: 14px;
    line-height: 1.7em;
  }
}
