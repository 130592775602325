@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.members-component {
  @include page-sizing;

  text-align: left;

  &__section {
    display: flex;
    flex-direction: column;
    // border: solid 1px yellow;

    @include tablet {
      flex-direction: row;
      // border: solid 1px red;
    }
  }

  &__spacing {
    padding-top: 4rem;
  }

  &__card {
    @include tablet {
      display: flex;

      justify-content: center;
    }
  }

  &__bin {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 27px 0 10px;
    color: $eacc-grey;
    font-size: 0.7rem;
    line-height: 1rem;
    font-weight: 500;
    // border: solid 1px red;

    @include desktop {
    }
  }

  &__topbox {
    padding-top: 10px;
    @include tablet {
      display: flex;
      flex-direction: column;
      // border: solid 1px purple;
      width: 57.8%;
      margin-right: 5.5%;
    }
  }

  &__company {
    color: black;
    font-size: 1rem;
    font-weight: 500;
  }

  &__details {
    flex-wrap: wrap;
    display: flex;
    margin: 1rem 0 0.5rem 0;
  }
  &__text {
    font-size: 11px;

    line-height: 23.8px;
    font-weight: 500;

    &--padding {
      margin-bottom: 9px;
      min-height: 23px;
    }
  }

  &__text-wrapper {
    padding-bottom: 1em;
  }

  &__numbers {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__additional {
    // border: solid 1px blue;
    height: 100%;
    @include tablet {
      width: 36.7%;
    }
  }
  &__expand {
    @include expandable-card;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include tablet {
    }
  }

  &__subheader {
    font-size: 0.8rem;
    color: $eacc-green;
    font-weight: 600;
    margin: 0 0 0 1rem;
  }

  &__container {
  }

  &__web {
    display: flex;
    flex-wrap: wrap;
  }

  &__weblink {
    color: $eacc-green;
    text-decoration: none;
    padding-right: 4px;
  }

  &__button {
    cursor: pointer;
    height: 25px;
    width: 25px;
  }

  &__logo {
    max-height: 150px;
    max-width: 150px;
    padding: 1rem 0;
  }
  &__expanded {
    display: flex;
    flex-direction: column;
  }

  &__profile-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__list {
    list-style: inside;
    font-size: 11px;
    line-height: 23.8px;
    font-weight: 500;
  }

  &__list-header {
    margin: 0.5rem 0;
    font-size: 11px;
  }
}
