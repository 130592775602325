@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.about {
  @include page-sizing;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    margin-top: 6rem;
    width: 100%;
    box-sizing: border-box;

    &--mission {
      margin-top: 2rem;
    }

    @include tablet {
      // width: 60rem;
    }

    @include desktop {
      width: 70rem;
    }
  }
  &__title {
    color: #333;
    margin: 2rem;
    font-size: 24px;
    font-weight: 500;

    @include tablet {
      font-size: 32px;
    }
  }

  &__line {
    border-top: solid 3px green;
    width: 7rem;
  }

  &__text {
    box-sizing: border-box;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    align-self: center;  
    width: 100%;
    @include tablet {
      align-self: flex-start;

    }
  }
}
