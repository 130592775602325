@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.question {
  @include expandable-card;
  margin-bottom: 2.75%;
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__icon {
    height: 22px;
  }

  &__header {
    color: black;
    margin: 2.75% 0;
  }

  &__title {
    font-weight: 600;
    line-height: 1.4em;
    font-size: 16px;
  }

  &__answer {
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 1.7em;
    font-weight: 500;

    &:nth-of-type(1) {
      padding-top: 20px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &__answer-wrapper {
    animation: color-fade 1s;
    background-color: white;
  }

  &__link {
    @include link-styling;
    font-size: 14px;
  }

  &__color {
    & h4:first-child {
      transition: all 1s;

      color: #333;
    }
  }
}

@keyframes color-fade {
  from {
    background-color: rgb(247, 247, 247);
  }
  to {
    background-color: white;
  }
}
