@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.nav {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  background-color: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 100%;

  @include desktop {
    position: sticky;
    height: 100%;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    width: 100%;
  }
  &__list {
    display: none;
    @include desktop {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      width: 910px;
      height: 120px;
      margin-right: 2rem;
      display: flex;

      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
    }

    &--shrink {
      @include desktop {
        height: 85px;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__list-item {
    @include nav-list-item;
    font-weight: 600;

    &--board {
      position: relative;
    }
  }

  &__board {
    cursor: pointer;
    height: 120%;
    display: flex;
    align-items: center;

    &:hover {
      & > li {
        color: #949494;
      }
    }
  }

  &__board-nav {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    position: absolute;
    top: 75px;
    translate: -200px 0;
    background-color: white;
    left: 0;
    padding: 20px 0;
    width: 280px;

    &--shrink {
      @include desktop {
        top: 60px;
      }
    }
  }

  &__board-list {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    width: 9rem;
    margin: 1rem 0;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;

    @include tablet {
      width: 14rem;
      &:hover {
        width: 14.5rem;
      }
    }

    &--shrink {
      @include desktop {
        width: 9rem;
      }
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__burger {
    cursor: pointer;
    margin-right: 3rem;
    box-sizing: border-box;
    width: 35px;
    height: 35px;

    @include desktop {
      display: none;
    }
  }
  &__arrow {
    margin-left: 2px;
    height: 14px;
  }
}
