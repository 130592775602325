@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.radon {
  @include page-sizing;

  &__list-item {
    color: #666;
    font-size: 14px;
  }

  &__link {
    @include link-styling;
    font-size: 14px;
  }

  &__image {
    max-width: 100%;
    margin: 2.75% 0;
  }
}
