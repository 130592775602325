@use "../../../../styles/partials/colors" as *;
@use "../../../../styles/partials/mixins" as *;

.radon-member {
  width: 47.25%;
  margin-right: 5.5%;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  @include desktop {
    width: 20.875%;
    margin-right: 5.5%;

    &:nth-of-type(2n) {
      margin-right: 5.5%;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.7em;
  }
  &__info {
    display: flex;
    flex-direction: column;
  }

  &__link {
    @include link-styling;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.7em;
    margin-bottom: 12px;
  }

  &__image {
    margin: 3rem 0 1em;
    max-width: 100%;
    height: auto;
  }
}
