@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.radon-nav {
  display: flex;

  background-color: black;
  color: white;
  padding: 27px 0;

  &__icon-wrapper {
    display: flex;
    position: relative;
    margin: auto;
    justify-content: flex-end;
    width: 80%;

    @include tablet {
      display: none;
    }
  }

  &__icon {
    height: 32px;
  }

  &__list {
    display: none;
    @include tablet {
      display: flex;
      width: 100%;
      width: 80%;
      max-width: 1080px;
      margin: auto;
    }
  }

  &__mobile-list {
    border-top: $eacc-green 4px solid;
    background-color: black;
    position: absolute;
    top: 120%;
    width: 100%;
    padding: 5%;
    animation: 0.5s radon-nav;
    height: 230px;
    @include tablet {
      display: none;
    }
  }

  &__mobile-list-item {
    @include nav-list-item;
    color: white;
    list-style-type: none;
    font-weight: 600;
    padding: 10px 5%;
    &:first-of-type {
      margin-left: 0;
    }
  }

  &__list-item {
    @include nav-list-item;
    color: white;
    list-style-type: none;
    font-weight: 600;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

@keyframes radon-nav {
  from {
    height: 1px;
    overflow-y: hidden;
  }
  to {
    height: 230px;
    overflow-y: none;
  }
}
