@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.job-post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2% 0;

  @include desktop {
    flex-direction: row;
    width: 80%;
  }

  &__text {
    &--bold {
      color: #666;
      font-weight: 700;
    }
  }

  &__button {
    @include green-button;
    @include button-animation;
    font-size: 16px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    @include desktop {
      margin: 0;
    }
  }
}
