@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.map-page {
  @include page-sizing;

  &__header {
    text-align: center;
    font-size: 36px;
    color: #1f1f1f;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
    margin-bottom: 2.75%;
  }

  &__container {
    display: flex;
    margin: 5px;
    max-width: none;
    min-width: 100px;
    min-height: 500px;

    border: 1px solid lightgray;

    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  &__member {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: sans-serif;
    color: black;
    font-size: 13px;
    line-height: 1.7em;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid lightgray;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }

  &__image {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
    height: 32px;
    width: 32px;
  }

  &__company {
    padding: 10px;
  }

  &__members-list {
    min-width: 300px;
    max-height: 500px;
    overflow-y: scroll;

    &--mobile {
      height: 200px;
      @include tablet {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }
  &__divider {
    margin: auto;
    background-color: $eacc-green;
    width: 100px;
    height: 3px;
    margin-bottom: 40px;

    &--bottom {
      margin-bottom: 0px;
      margin-top: 40px;
    }
  }
}
