@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.drug {
  @include page-sizing;
  padding-top: 2rem;

  @include tablet {
    padding-top: 0;
  }

  &__text {
    font-size: 14px;
    line-height: 1.7em;
    padding-bottom: 1em;
  }

  &__list {
    padding: 0 0 1em 1em;
  }

  &__list-item {
    color: #666;
    list-style-type: disc;
    font-size: 14px;
    line-height: 26px;
  }

  &__subheader {
    padding-bottom: 1em;
  }

  &__link {
    @include link-styling;
  }
}
