@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;
.radon-members {
  @include page-sizing;

  &__header {
    color: #333;
    padding: 2% 0;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
