@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.covid {
  @include page-sizing;

  &__text {
    margin-bottom: 1em;
  }

  &__link {
    @include link-styling;
    font-size: 14px;
  }

  &__list-item {
    list-style-position: inside;
  }
  & p:last-of-type {
    margin-bottom: 0;
  }
}
