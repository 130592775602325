@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;

.subscribe {
  padding-top: 90px;
  padding-bottom: 60px;
  background-color: #757b81;
  -webkit-animation: swing-in-top-fwd 1s ease-out both;
  animation: swing-in-top-fwd 1s ease-out both;

  &__success {
    @include flex-center;
  }

  &__message {
    padding-top: 1.5rem;
    color: white;
  }

  &__divider {
    margin: auto;
    background-color: white;
    width: 100px;
    height: 3px;
    margin-bottom: 40px;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    color: white;
    width: 80%;
    max-width: 710px;
    padding: 2% 0;
    margin: auto;
    position: relative;
  }

  &__title {
    text-align: center;
    color: white;

    font-size: 36px;
    line-height: 1em;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  &__text {
    text-align: left;
    font-weight: 500;
    line-height: 1.9em;
    font-size: 1rem;
    word-spacing: 3px;
    color: white;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 80%;

    margin: auto;

    @include tablet {
      max-width: 500px;
    }
  }
  &__input {
    padding: 1rem;
    width: 100%;
    margin: auto;
    margin-bottom: 1rem;
    outline: none;
    color: #999;

    &::placeholder {
      font-family: $font-stack;
      color: rgb(117, 117, 117);
      font-size: 14px;
      font-weight: 400;
    }

    &--error {
      border: solid 2px red;
    }
  }

  &__button {
    @include green-button;
    @include button-animation;

    font-weight: 600;
    font-size: 20px;
    align-self: flex-end;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-12-27 21:56:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
