@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.footer {
  background-color: black;
  color: #666;
  padding: 20px 0;

  &__info {
    text-align: center;
    margin: 0 auto;
    max-width: 1080px;

    p {
      margin: 0;
      line-height: 1.5;

      @media (max-width: 768px) {
        // Break text into separate lines for mobile
        .footer__line {
          display: block; // Ensure each line is its own block
          margin-bottom: 0.5rem; // Add spacing between lines
        }
      }

      @media (min-width: 768px) {
        // Merge everything into a single line for desktop
        .footer__line {
          display: inline; // Combine all into one line
          margin: 0;
          &:not(:last-child)::after {
            content: " | "; // Add separator between segments
            margin: 0 0.25rem; // Add spacing around separators
          }
        }
      }
    }
  }

  &__link {
    color: white;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }

  &__image {
    width: 80px;
    vertical-align: middle;
  }
}
