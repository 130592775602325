@use "./styles/partials/typography" as *;

* {
  margin: 0;
  padding: 0;
  transition: width 1s, height 1s;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7em;
  font-weight: 500;
}

p {
  color: #666;
  font-size: 14px;
  line-height: 1.7em;
}

h2,
h3,
h4,
h5 {
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
