@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.jobs {
  @include page-sizing;

  &__text {
    padding: 2% 0;
  }
  &__link {
    @include link-styling;
  }

  a {
    margin-left: 0.2rem;
  }
}
