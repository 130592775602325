@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.press {
  @include page-sizing;

  &__header {
    padding-bottom: 1em;
  }

  &__text {
    padding-bottom: 1em;
    line-height: 1.7em;

    &--bold {
      font-weight: 700;
    }

    &--underline {
      text-decoration: underline;
    }

    &:last-of-type {
      padding: 0;
    }
  }

  &__list {
    list-style-position: inside;
    padding-bottom: 1em;
  }

  &__list-item {
    color: #666;
    font-size: 14px;
    line-height: 1.7em;
  }

  &__link {
    @include link-styling;
  }

  &__logo {
    width: 261px;
  }
}
