@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.article {
  display: flex;
  background-color: $eacc-lightgrey;
  width: 100%;

  &__image {
    display: none;
    @include tablet {
      display: flex;
      background-position: top left;
      background-image: linear-gradient(180deg, rgba(15, 58, 2, 0.5) 0%, rgba(10, 1, 1, 0.4) 100%),
        url(https://www.eaccanada.ca/wp-content/uploads/2021/05/image001.png);
      width: 50%;
    }
  }

  &__container {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    padding-top: 8%;
    padding-right: 6%;
    padding-bottom: 8%;
    padding-left: 6%;
    @include tablet {
      width: 50%;
    }
  }

  &__title {
    margin-right: auto;
    max-width: 450px;
    margin-top: 40px;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.4em;
    padding-bottom: 10px;
    color: #1f1f1f;
  }

  &__text {
    margin-right: auto;
    max-width: 450px;
    color: #666;
    font-weight: 500;
    line-height: 1.9em;
    padding-bottom: 20px;
    font-size: 1rem;
  }

  &__button {
    @include green-button;
    margin-top: 1rem;
    transition: color ease 0.3s, background-color ease 0.3s;

    &:hover {
      background-color: white;
      color: #33924a;
    }
  }

  &__link {
    text-decoration: none;
  }
}
