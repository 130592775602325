@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;

.contact {
  background-image: url(../../../assets/background.jpeg);
  height: 100%;
  padding-top: 90px;
  padding-bottom: 90px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  @include tablet {
    // padding: 4% 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1080px;
    margin: auto;
    @include desktop {
      flex-direction: row;
    }
  }

  &__reachus {
    text-align: left;
    margin-bottom: 2rem;
    background-color: #ffffff;
    padding-top: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;

    @include desktop {
      margin-bottom: 0rem;

      width: 31.33333%;
      margin-right: 3%;
    }
  }

  &__info-wrapper {
    display: flex;

    align-items: center;
    margin-bottom: 11px;
  }
  &__icon {
    width: 22px;
    align-self: flex-start;
    margin-right: 15px;
  }

  &__social-icon {
    background-color: #7dda24;
    width: 40px;
    padding: 0.5rem;
    border-radius: 2rem;

    &--linked {
      padding: 0;
      background-color: #fff;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__title {
    color: #1f1f1f;

    font-size: 22px;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
  }

  &__text {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7em;
    text-decoration: none;
  }
  &__icon-wrapper {
    display: flex;
    width: 50%;
    margin: auto;
    justify-content: space-around;
  }

  &__form {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
    padding-top: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;

    @include desktop {
      width: 65.667%;
    }
  }

  &__input {
    padding: 1rem;
    margin-bottom: 3%;
    border: rgba(0, 0, 0, 0.12) solid 1px;
    outline: none;
    &::placeholder {
      font-family: $font-stack;
    }

    &--textarea {
      min-height: 150px;
      resize: none;
    }
  }

  &__button {
    @include green-button;
    width: 200px;
    height: 45px;
    padding: 5.4px 0;
    align-self: flex-end;
    letter-spacing: 2px;
    font-size: 18px;
  }

  &__success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: white;
    padding-top: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;

    @include desktop {
      width: 65.667%;
    }
  }
}
