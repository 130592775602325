@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.mobile {
  margin-top: 87px;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include tablet {
    margin-top: 150px;
  }

  @include desktop {
    display: none;
  }

  &__dropdown {
    animation: nav-slide 1s;
    transition: all 0s;
    background-color: white;
    border-top: 2px solid $eacc-green;
    width: 82%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    padding: 1rem 0;
  }

  &__list {
    text-decoration: none;

    &--board {
      padding-left: 2rem;
    }
  }

  &__link {
    @include link-styling;
    width: 100%;
  }

  &__item {
    cursor: pointer;
    font-weight: 600;
    color: rgb(82, 82, 82);
    text-align: left;
    list-style-type: none;
    margin: 1rem;
    width: 92%;
    padding: 1rem;
    margin: 0 1rem;
    &:hover {
      transition: 0.5s;
      background-color: rgb(245, 243, 243);
    }
  }
}

@keyframes nav-slide {
  from {
    max-height: 1rem;
    overflow-y: hidden;
  }
  to {
    max-height: 100rem;
    overflow-y: none;
  }
}
