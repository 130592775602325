@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.committee {
  @include page-sizing;

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 700;
    line-height: 1.7em;
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &--bold {
      color: black;
      font-size: 1.4rem;
      text-decoration: underline;
    }
  }

  &__body {
    font-weight: 500;
    line-height: 1.7em;
    font-size: 1rem;
    color: #666;

    margin-bottom: 3.5rem;
  }
}
