@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.drug {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 1rem 3rem;

  &__subheader {
    font-size: 1rem;
  }

  &__list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    &-item {
      flex-wrap: wrap;
      width: 100%;
      font-size: 0.95rem;
    }
  }
  &__link {
    width: 100%;
    word-wrap: break-word;
  }
}
