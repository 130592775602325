@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  background-image: url(../../assets/background2.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  bottom: 0;
  left: 0;
  width: 100%;

  &--home {
    background-image: url(../../assets/background.jpeg);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      #329115 0%,
      rgba(58, 140, 40, 0.7) 100%
    );
    background-color: rgba(255, 255, 255, 0);
    padding-top: 40px;
    padding-bottom: 30px;
    z-index: 6;
    height: 100%;
    animation: fade-in 1s;

    &--homepage {
      padding-top: 0;

      @include tablet {
        padding-top: 40px;
        padding-bottom: 30px;
      }
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    width: 80%;
    max-width: 800px;
    text-align: center;
    margin: auto;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.4em;
    color: white;
    @include tablet {
      font-size: 3.8rem;
      margin-bottom: 2.7rem;
      margin-top: 2rem;
    }

    &--welcome {
      padding-top: 0;
      font-size: 1.5rem;
      line-height: 4rem;
      @include tablet {
        font-size: 3.8rem;
        margin-bottom: 2.7rem;
      }
    }
  }

  &__text {
    font-size: 19px;
    line-height: 1.8em;
    font-weight: 500;
    color: white;
  }

  &__button {
    @include button-animation;
    position: relative;
    cursor: pointer;
    margin: 20px 0 0rem;
    text-decoration: none;
    font-size: 20px;
    height: 3rem;
    letter-spacing: 2px;
    background-color: #0067c5;
    color: white;
    border: 2px solid white;
    font-weight: 500;
    padding: 0.3em 1em;
    line-height: 1.7em;
    margin-top: 2rem;

    @include tablet {
      margin: 20px 0 4rem;
      margin-top: 4rem;
    }
  }

  &__subheader {
    color: white;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.8em;
    width: 80%;
    padding: 0 0.25rem;

    @include tablet {
      font-size: 22px;
    }
  }

  &__members {
    display: flex;
    background-color: #4c4c4c;
    padding: 30px 0;
    width: 100%;
    justify-content: center;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 70%;

    @include tablet {
      flex-direction: row;
      justify-content: space-around;
    }
    @include desktop {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__member-button {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7em;
    width: 340px;
    height: 50px;
    color: white;
    border: 2px solid white;
    letter-spacing: 2px;
    transition: 0.5s;
    position: relative;

    &:after {
      content: ">";
      color: white;
      position: absolute;
      opacity: 0;
      top: 50%;
      translate: 0 -50%;
      right: -10px;
      transition: 0.5s;
    }

    &:hover {
      padding-right: 12px;
    }

    &--become {
      background-color: $eacc-green;
      align-self: flex-end;
      margin-bottom: 1rem;

      @include tablet {
        margin-right: 1rem;
        margin-top: 1rem;
      }

      &:hover:after {
        opacity: 1;
        right: 7%;
      }

      @include desktop {
        // margin-bottom: 0;
      }
    }
    &--find {
      background-color: $eacc-grey;
      align-self: flex-start;

      @include tablet {
        margin-left: 1rem;
        margin-top: 1rem;
      }
      &:hover:after {
        opacity: 1;
        right: 10%;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
