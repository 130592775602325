@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.radon-resources {
  @include page-sizing;

  &__subheader {
    color: #333;
    padding-bottom: 25px;
  }

  &__link {
    @include link-styling;
    font-size: 14px;
  }

  &__list {
    padding: 0 0 23px 1em;
    line-height: 26px;
  }

  &__list-item {
    list-style-type: disc;
    font-size: 14px;
    color: #666;
  }

  &__image {
    padding-top: 23px;
    padding-bottom: 10px;
  }
}
