@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.events {
  display: flex;
  flex-direction: column;
  padding: 2% 0;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  padding: 85px 0 81px;
  @include tablet {
    flex-direction: row;
  }
  &__card {
    @include tablet {
      width: 50%;

      &--padding {
        margin-right: 5.5%;
      }
    }
  }

  &__heading {
    color: #333;
    margin-bottom: 5.82%;
    font-weight: 700;

    &--padding {
      margin-top: 1em;

      @include tablet {
        margin-top: 0;
      }
    }
  }
  &__subheading {
    padding-bottom: 1em;
  }

  &__info {
    font-size: 18px;
    line-height: 1.75rem;
    color: #333;
    padding-bottom: 20px;
    margin-bottom: 5.82%;
  }

  &__button {
    @include green-button;
    @include button-animation;
    font-size: 20px;
    margin-top: 3.8rem;
    position: relative;
    background-color: white;
    color: $eacc-green;

    &::after {
      color: $eacc-green;
    }
  }

  &__text {
    line-height: 1.7em;
    font-size: 14px;

    &--padding {
      margin-bottom: 1em;
    }
  }

  &__link {
    @include link-styling;
    font-size: 14px;
  }

  &__divider {
    height: 1px;
    background-color: $eacc-green;
    margin: 5.82% 0;
  }

  &__image {
    height: 300px;
    float: right;
  }
}
