@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.gallery {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet {
    justify-content: flex-start;
  }
  @include desktop {
  }

  &__year {
    text-align: left;
    color: black;
    padding-bottom: 2rem;

    @include tablet {
      padding: 0 0 0 1rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include tablet {
      flex-direction: row;
    }
  }

  &__cover {
    // border: solid 1px red;
    margin-bottom: 2rem;
    @include tablet {
      margin: 1rem;
      width: 29.6667%;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__image {
    cursor: pointer;
    margin: 1rem;
    width: 100%;

    object-fit: contain;
    @include tablet {
      max-height: 30rem;
    }
  }

  &__title {
    text-align: center;
    font-weight: 500;
    color: black;
    padding-bottom: 1rem;
  }
}
