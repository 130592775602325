@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/mixins" as *;

.drug-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: black;
  height: 5rem;

  @include desktop {
    justify-content: start;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    text-decoration: none;
    padding-right: 2rem;
    color: white;
    padding: 1rem;
  }

  &__text {
    color: white;
  }

  &__list {
    display: none;
    color: white;

    @include desktop {
      margin-right: auto;
      margin-left: auto;
      display: flex;
    }
  }
  &__burger {
    margin-right: 3rem;
    width: 2.25rem;

    @include desktop {
      display: none;
    }
  }
}
